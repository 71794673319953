// Language: javascript

import React from "react";
import bcrypt from "bcryptjs";

import SnapAd from "./Components/ads/SnapAd";
import Home from "./Components/Home/Home";
import RightAd from "./Components/ads/RightAd";
import Password from "./Components/Password";
import Navbar from "./Components/Navbar/Navbar";
import Header from "./Components/Header";
import Questions from "./Components/Questions/Questions";
import Gallery from "./Components/Gallery/Gallery";
import Fitness from "./Components/Fitness/Fitness";
import Shorts from "./Components/Shorts/Shorts";
import Maintenance from "./Components/Maintenance/Maintenance";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: "Password",
            value: "",
        };
        this.pages = [
            { name: "Home" },
            { name: "Gallery" },
            { name: "Fitness" },
            { name: "Shorts" },
            { name: "???" },
        ];

        this.hash =
            "$2b$10$FGKafa8QR6t3DfCfB7Kx5O1sjB7QreBBFTMD83DY/UjRtC11CBdda";

        this.handleChange = this.handleChange.bind(this);
        this.checkPassword = this.checkPassword.bind(this);
    }

    handleChange(e) {
        const password = e.target.value;
        this.setState({ value: password });
    }

    checkPassword(event) {
        const password = this.state.value;
        if (bcrypt.compareSync(password, this.hash)) {
            this.setState({ currentPage: "Home" });
        }
        event.preventDefault();
    }

    getPage() {
        switch (this.state.currentPage) {
            case "Home":
                return <Home />;
            case "???":
                return <Questions />;
            case "Gallery":
                return <Gallery></Gallery>;
            case "Fitness":
                return <Fitness />;
            case "Shorts":
                return <Shorts />;
            default:
                return <></>;
        }
    }

    render() {
        if (this.state.currentPage === "Password") {
            const handleClick = () => this.setState({ currentPage: "Home" });

            return (
                // <Password
                // 	checkPassword={this.checkPassword}
                // 	handleChange={this.handleChange}
                // 	value={this.value}
                // />
                <Maintenance handleClick={handleClick}></Maintenance>
            );
        }

        const handleNavClick = (page) => {
            this.setState({ currentPage: page });
        };

        return (
            <>
                <SnapAd />
                <div className="content">
                    <Header />
                    <Navbar
                        links={this.pages}
                        active={this.state.currentPage}
                        handleNavClick={handleNavClick}
                    />
                    {this.getPage()}
                </div>
                <RightAd />
            </>
        );
    }
}

export default App;
