//Language : javascript

import React from "react";

const NavItem = (props) => {
	return (
		<li className="nav-item">
			<button
				href={props.link}
				className={
					props.active ? "nav-link-active nav-link" : "nav-link"
				}
				onClick={props.onClick}
			>
				<span className="nav-link">{props.name}</span>
			</button>
		</li>
	);
};

export default NavItem;
