import React from "react";
import Input from "./Input";
import Screen from "./Screen";

const responses = [
	"Is it because I’m fat?!",
	"Condense it into its purest form and shove it up your ass",
	"Go Team!!!",
	"Beware ye stretchy",
	"My eyes aren’t that good, you’re gonna have to read that for me",
	"Now why would you go saying something like that, that was uncalled for and blatantly disrespectful",
	"I can’t believe its not butter",
	"Have I ever told you about how I wish I could pull off jeans?",
	"I think I’m still at a point where I can tell myself it’s baby fat ",
	"My favorite song is by far “Life is a Highway”",
	"Sometimes I get the feeling you don’t think I’m a good singer",
	"I reckon you oughta rethink some major life choices",
	"I’ve always wanted to ride horses; but alas, I cant subject the poor horse to mi immense girth",
	"That reminds me, I need to water my oregano. I’m growing some in my room, it makes the place smell like one of those pizzerias that put too much oregano on every pie",
	"My lips always get really chap when I go to sleep. Do you think that's worth a doctors visit?",
	"I think my hemorrhoids are acting up again",
	"I got a rumbly in me tumbly, must’ve been something I ate",
	"Such vulgarities shan’t be tolerated",
	"Remember that time I was the elected dictator of my 9th grade science class, that was fun",
	"My favorite movie is undoubtedly Bedtime for Bonzo",
	"I still don’t know how to factor an equation",
	"If I ever wrote an autobiography, I would call it “It’s All About Me”",
	"Do you ever get the feeling that some of these are prewritten, randomly generated responses? Of course that would be absurd. Who would possibly commit copious amounts of time to such an outlandish thing. If someone were to do that they would really need to get a life. I bet the type of person to do that would be the type of person to make a website about themselves.",
	"I’m fully convinced there is a conspiracy against me",
	"Sometimes I break a sweat getting out of bed in the morning",
	"I don’t consider myself particularly inclined to fashion",
	"I own but one instrument… Its a banjo; I don’t play banjo",
	"Lately I’ve been addicted to solitaire",
	"I’ve grown somewhat attached to my calculator",
	"My dad bought an ice maker because the fridge makes it too slow for my family ice consumption (a 0 calorie snack), he returned it because he thought it was too loud, the one he bought to replace it for double the price is about twice as loud yet he swears it’s quieter.",
	"I’m quite parched",
	"I dream of going to Germany",
	"My tv is set to the kitchen Nightmares channel",
	"There’s something I need to talk to you about. For a while now I’ve been suspicious that you have a crush on me. Now I understand the extent of my immense attractiveness, but alas I simply don’t think it would work out. How about we stay friends.",
	"One of these days I’m gonna finally finish that damn book I’ve been reading for about a year",
	"The full contents of Connor’s Little Blue Book shall not be revealed (It’s nothing bad, just secret)",
	"My favorite Justin Timberlake song is Piano Man, I hate Justin Timberlake",
	"I loathe pastries",
	"My favorite type of wood is pine. It’s very light and very workable, ideal for small woodworking",
	"I listen to Christmas songs year round",
	"Do you ever get the feeling there's an existing sociology organization that keeps tabs on you",
	"My favorite enzyme is helicase and my favorite protein is beta galactose",
	"You know that reminds me of something very wise someone had once told me, if only I could remember what the hell they said",
	"I’ve been meaning to ask you, can I borrow some money? This website sure as hell ain’t cheap and I’m a bit short on cash",
	"I hardly ever burp, maybe like three times a year at most",
	"I still don’t know how many days are in each month",
	"I made up my own tongue twister, “Southern white rhinos writing on white paper”",
	"I think I’m a little out of shape",
	"I once dreamed of owning a boat farm that I would sail around international waters only coming to dock to barter for crops and livestock",
	"I’ve always been fascinated by the Interstate Highway system",
	"Do you ever wonder if people think about you? Right now you’re on my website and I’m probably none the wiser",
	"Congratulations!!! You’ve won!",
	"That’s a shame",
	"Don’t say that",
	"How could anyone not like you",
	"I really am one of a kind",
	"Is it that time of year where the turkey",
	"Oh my",
	"That sure is quite the conundrum",
	"Let us try to remain cordial, such vulgarities will not be tolerated",
	"I’ve always admired that about you",
	"I’m a monster when it comes to string cheese",
	"I have a strange fear of spaghetti-O’s",
	"I could totally go for some pizza right now",
	"I’m always in the mood for wings",
	"For me, the letter “Q” will always be synonymous with 6th president John Quincy Adams",
	"On 5/22/2022 I made not one but two mac n’ cheese sandwiches ",
	"It’s been way to long since I’ve last had a PB&J",
	"Even though it comes blasting out of me with the force of 1,000 moons, I still love dairy products and I take no precautions to prevent the horrendous outcome",
	"You’re being very mean",
	"My mom says I look alot like Rutherford B. Hayes, personally I don’t see it",
	"One of these days I want a horse and I will name the noble stead Banquo",
	"So… Read any good books lately?",
	"I didn’t shit from Fri-Sun (5/27-5/29) and only just began to pass bowel movements. I am gravely concerned not all of it has passed. What should I do? I think there is something stuck inside me. My intestines must be so backed up it’s gonna start spilling into my lungs. Help! Such developments in my irregular bowel movements have me gravely concerned due to my relationship with the chronic condition of hemorrhoids. Hemorrhoids involve a ruptured vein in the rectum that causes the excretion of blood with the passing of fecal matter. I have in fact sought medical assistance for that particular condition but alas, with such a storm brewing in my bowels, four days in the making with lackluster excretions being the only showing there is undoubtedly reason to believe there is a significantly larger entity  amassing deep within the depths of my bowels of which only the boldest of doctor’s fingers shall venture. I fear for the well being of my anus and directly linked tubular systems (colon, rectum, intestines, etc.). I fear what is to come.",
	"You have done more to yourself than I could ever hope to accomplish ",
	"If you wanna talk to someone you have a crush on, tell them about the Agricultural Adjustment Act, the Supreme Court didn’t eat it up but crushes tend to eat it up like poor farmers eat grain.",
	"A wooden whistle is only right twice a day",
	"The Hapsburgs were the only prominent  purebred human family",
	"My favorite chess piece is the rook",
	"Whilst your argument is unfounded and contradictory onto itself, I respect your take on the matter",
];

export default function Chat() {
	const [messages, setMessages] = React.useState([]);
	React.useEffect(() => {
		const screen = document.getElementById("screen");
		screen.scrollTop = screen.scrollHeight;
	}, [messages]);

	const [value, setValue] = React.useState("");

	const getResponse = () => {
		const random = Math.floor(Math.random() * responses.length);
		return responses[random];
	};

	const handleSubmit = (event) => {
		const response = getResponse();
		setMessages([...messages, value, response]);
		setValue("");
		event.preventDefault();
	};

	const handleChange = (event) => {
		setValue(event.target.value);
	};

	return (
		<div className="chat">
			<div className="chat-window">
				<Screen messages={messages} />
				<Input
					handleSubmit={handleSubmit}
					handleChange={handleChange}
					value={value}
				/>
			</div>
		</div>
	);
}
