import React from "react";
import snapAd from "./snap-ad.jpeg";
import CloseButton from "./CloseButton";

const SnapAd = () => {
	const link =
		"https://www.snapchat.com/add/connorfalkowski?share_id=N0U1NUI4&locale=en_US";

	return (
		<div className="snap-ad">
			<a href={link}>
				<img src={snapAd} alt="Snapchat Advert"></img>
				<CloseButton />
			</a>
		</div>
	);
};

export default SnapAd;
