import React from "react";
import ConnorImg from "./ConnorImg";
import ResizeBar from "./ResizeBar";

export default class Resize extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: "22%",
        };
    }

    handleResize = (e) => {
        this.setState({
            width: e.target.value.toString() + "%",
        });
    };

    render() {
        return (
            <div className="resize">
                <h2>Try That For Size</h2>
                <h3>Width: {this.state.width}</h3>
                <ConnorImg width={this.state.width} />
                <ResizeBar
                    width={Number(
                        this.state.width.substring(
                            0,
                            this.state.width.length - 1
                        )
                    )}
                    handleResize={this.handleResize}
                />
            </div>
        );
    }
}
