import React from "react";
import "./CloseButton.css";

const CloseButton = () => {
    return (
        <div className="close-button">
            <span></span>
            <span></span>
        </div>
    );
};

export default CloseButton;
