import React from "react";

export default function Password(props) {
    return (
        <>
            <div className="password">
                <form onSubmit={props.checkPassword}>
                    <label>
                        Password:
                        <input
                            type="password"
                            value={props.value}
                            onChange={props.handleChange}
                        ></input>
                    </label>
                    <input type="submit"></input>
                </form>
            </div>
        </>
    );
}