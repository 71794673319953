/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import ConnorJefferson from "./Screenshot_20210616-162610_Photos.JPG";
import { Fireworks } from "fireworks/lib/react";

function importAll(r) {
	let images = {};
	r.keys().forEach((item, index) => {
		images[item.replace("./", "")] = r(item);
	});
	return images;
}

export default class Gallery extends React.Component {
	constructor() {
		super();
		const images = importAll(
			require.context("./images", false, /\.(png|jpe?g|svg|JPG)$/)
		);

		let uris = [];
		Object.keys(images).forEach((key) => {
			uris.push(images[key]);
		});

		this.imgs = uris.map((image, index) => {
			return (
				<img
					key={index}
					src={image}
					alt="Gallery"
					className="gallery-image"
				/>
			);
		});

		this.state = {
			page: "Images",
		};
	}

	changePage() {
		this.setState({ page: "Jefferson" });
	}

	getPage() {
		switch (this.state.page) {
			case "Images":
				console.log("imaghes");
				return (
					<>
						{this.imgs}
						<button
							className="to-jeff"
							onClick={() => this.changePage()}
						>
							Ascend
						</button>
					</>
				);
			case "Jefferson":
				let fxProps = {
					count: 3,
					interval: 200,
					calc: (props, i) => ({
						...props,
						x: (i + 1) * (window.innerWidth / 3) - (i + 1) * 100,
						y: 200 + Math.random() * 100 - 50 + (i === 2 ? -80 : 0),
					}),
				};
				return (
					<>
						<img
							src={ConnorJefferson}
							alt="Connor Jefferson"
							className="gallery-image"
						/>
						<Fireworks {...fxProps} />
					</>
				);
			default:
				console.log("default");
				return <></>;
		}
	}

	render() {
		return <div className="photos">{this.getPage()}</div>;
	}
}
