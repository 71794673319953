import React from "react";

export default function Fitness() {
	return (
		<div className="fitness">
			<h2>Fitness Talks</h2>
			<div className="videos">
				<div className="video">
					<iframe
						src="https://www.youtube.com/embed/hy3ct0liQlQ"
						title="YouTube video player"
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
					></iframe>
				</div>
				<div className="video">
					<iframe
						src="https://www.youtube.com/embed/ccLM6W-4LX0"
						title="YouTube video player"
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
					></iframe>
				</div>
				<div className="video">
					<iframe
						src="https://www.youtube.com/embed/maQ4Gj_4dPk"
						title="YouTube video player"
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
					></iframe>
				</div>
				<div className="video">
					<iframe
						src="https://www.youtube.com/embed/KHaAPXUw8Zo"
						title="YouTube video player"
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
					></iframe>
				</div>
				<h2>The Connor (Quarter) Mile</h2>
				<div className="video">
					<iframe
						src="https://www.youtube.com/embed/Nd24-pvTxaY"
						title="YouTube video player"
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
					></iframe>
				</div>
			</div>
		</div>
	);
}
