import React from "react";

export default function Input(props) {
	return (
		<div className="input">
			<form onSubmit={props.handleSubmit}>
				<input
					type="text"
					name="field1"
					className="field1"
					placeholder="Your Message"
					value={props.value}
					onChange={props.handleChange}
				></input>
				<input
					type="submit"
					value="Send"
					className="message-submit"
				></input>
			</form>
		</div>
	);
}
