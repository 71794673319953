import React from "react";
import bcrypt from "bcryptjs";

import Password from "../Password";
import Tab from "../Tab";

export default class Questions extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currentPage: "Password",
			value: "",
		};

		this.hash =
			"$2a$10$pc8kvzYESAnLZrK9irnlyulXy8ZoWi/nldCtRTO/Nch9Nxosx.Ee2";

		this.handleChange = this.handleChange.bind(this);
		this.checkPassword = this.checkPassword.bind(this);
	}

	handleChange(e) {
		const password = e.target.value;
		this.setState({ value: password });
	}

	checkPassword(event) {
		const password = this.state.value;
		if (bcrypt.compareSync(password, this.hash)) {
			this.setState({ currentPage: "Page" });
		}

		event.preventDefault();
	}

	getPage() {
		switch (this.state.currentPage) {
			case "Password":
				return (
					<Password
						checkPassword={this.checkPassword}
						handleChange={this.handleChange}
						value={this.value}
					/>
				);
			case "Page":
				return (
					<div className="body">
						<h2>Connor’s Food Review</h2>
						<p>
							<Tab />
							Food has played a fundamental role in making me who
							I am today, as such I have particularly strong
							opinions on just about every food item and beyond.
							Despite my slim appearance, I can often be found
							indulging in my irresistible insatiable urge to
							consume. As such, I have taken the time to compile
							my complex and long running relationship with food
							(among other things in which I’ve consumed).
						</p>
						<p>
							<Tab />I find it most appropriate to start with what
							has become a staple to my daily routine: Mini-meals.
							Mini-meals are a staple of the everyday. As one who
							to many people’s surprise does not eat throughout
							the day, a mini-meal will always be my first
							indulgence. Upon return from school I will prepare
							one to two mini-meals, they often consist of fridge
							or freezer finds. Favorites consist of mozzarella
							sticks, hot dogs, these little doughy mozzarella
							balls (I don’t know what they’re called), chips &
							dip, leftover tacos, and much much more.
						</p>
						<p>
							<Tab />
							Hotdogs in particular are very abundant, with that
							being said, I have had more time with them than just
							about any other food. I have found what I deem to be
							the optimal hotdog topping arrangement. A 1:2
							mustard to ketchup ratio favoring the ketchup. The
							thing is, mustard talks, it’s very loud. Oftentimes
							ketchup is the loud rambunctious condiment but under
							hotdog circumstances it proves to be the voice of
							reason with its vinegar subduing the pungent nature
							of the mustard.
						</p>
						<p>
							<Tab />
							TThough I may look like it, I certainly haven’t
							encountered every food. Some foods I would like to
							try for an array of reasons are as follows: lamb,
							frog legs, caviar, beef wellington, wagyu. I’m sure
							there are countless others but that is all I can
							muster up for now.
						</p>
						<p>
							<Tab />
							Many people assume I would be a lover of pastries
							but in truth they are among my least favorite food
							categories. This is surprising to many but alas my
							unwavering pallet has never enjoyed them which is
							most peculiar given I enjoy carbs more than
							anything. I find them to be dry with a flavor
							profile nowhere near strong enough to compensate.
							They are disruptive to my enjoyment of other
							delectables such as pie, why does pie crust have to
							be a pastry. I recon any pie would be better in just
							plain ol’ bread.
						</p>
						<p>
							<Tab />
							Going as far back as eight grade gum has been a
							reoccurring staple of my school inventory. In eighth
							grade I always had copious amounts of Juicy Fruit on
							my person at all times. This year I find myself
							seemingly always with Extra Spearmint gum. Whilst in
							eight grade I was practically running a gum charity,
							giving it away faster than I could get my hands on
							more, this year I am rather protective with those
							getting it being required to follow a strict
							protocol which has only been violated by but one
							man. The ritual is that the gum must be fed to the
							recipient by me hence why I only give to my closest
							friends; it is significantly easier to simply say no
							than to explain that I must feed it to them. The
							individual who has defied such tradition has since
							been blacklisted from the gum recipients.
						</p>
						<p>
							<Tab />
							It would be a sin to talk about food and not delve
							into my eating history without acknowledgement of
							past things eaten that were not necessarily edible.
							By far my most outlandish feat was an entire pencil
							but in truth I had faked that. However everything
							else was real. I figure the most effective way to
							communicate the nonedibles (and other outlandish
							things) I’ve consumed is via an itemized list in
							order of descending normalcy:
						</p>
						<ul>
							<li>Paper</li>
							<li>Peanut butter under my bed</li>
							<li>Bag of breadcrumbs next to my bed</li>
							<li>Graphite</li>
							<li>Wrappers</li>
							<li>Lollipop out of someone else's mouth</li>
							<li>White out (That was in fifth grade)</li>
							<li>Hand sanitizer (That was at a party)</li>
							<li>
								Silica Gel (The stuff in the packets to keep
								stuff dry)(I've ate tons of it in 8th grade)
							</li>
							<li>House paint</li>
						</ul>
						<p>
							<Tab />
							Ever since freshman year I’ve toned it down a
							considerable bit and now my food feats are rather
							mundane such as breakfast brownies, morning coke,
							Grandma’s (Insanely dry) cookies, breakfast candy;
							in truth its not really all to interesting rather
							whatever I sporadically bring to second period
							lunch.
						</p>
						<div className="manifesto">
							<h2>The Fat Kid Manifesto</h2>
							<p>
								<Tab />
								The world finds itself at yet another
								crossroads. We are at a point in which the
								united States, leader of Western civilization,
								is about to breach a 50% obesity rate. I find it
								only fair that as a society we begin to
								accommodate the larger folk of whom I’m apart of
								who shall reign supreme. Funded by the corporate
								supremacy of honorable enterprises, such as the
								likeness of Coca-Cola and McDonalds. The
								products of the fat market shall fuel an
								uprising long overdue. Products such as the west
								elixir manufactured but the Coca-Cola Company
								are irreplaceable in its role of topping the
								scales of the weight distribution across the
								country.
							</p>
							<p>
								<Tab />
								To construct the new world order a system of
								health entrapment should be implemented. I dream
								of a land with Cole at every fountain and a
								Quarter Pounder in every vending machine.
								Implementing such changes would undoubtedly
								bring about a perpetually overweight Soviet
								structured around a culture of
								gluttony.Humanity, ever gluttonous by nature,
								shall embrace to new world order.
							</p>
							<p>
								<Tab />
								The antithesis of our plan will be the demise of
								all fitness centers; to continue such centers
								that promote “health” would be detrimental to
								the very principles of my movement. Those who
								resist change will run out of options as bans on
								salads will be implemented. It is my dream to
								implement a calorie tax, the less calories a
								food product has the greater the tax on the sale
								of said items.
							</p>
							<p>
								<Tab />
								It will not be easy but the implementation of my
								health ideologies can bring about the change
								required for humanity to finally exist in
								overweight harmony. All the fat children will
								join hands. World peace will be achieved as all
								are far too out of shape to fight. My vision
								shall change the world.
							</p>
						</div>
					</div>
				);
			default:
				return <></>;
		}
	}

	render() {
		return this.getPage();
	}
}
